<template>
  <div class="form_edit">
    <el-form @submit.native.prevent="" ref="form">
      <el-form-item :label="label" class="item" :required="not_empty">
        
        <title_cat class="title_cat" :fw="fw" :size="size" v-if="edit_etat === false && !value" icon="edit" :clickable="true" @click="click_edit">{{ placeholder }}</title_cat>
        <title_cat class="title_cat" :fw="fw" :size="size" v-else-if="edit_etat === false && value" icon="edit" :clickable="true" @click="click_edit">{{ value }}</title_cat>
        
        <div v-else>
          <el-input ref="input" v-if="type === 'textarea'" type="textarea" :autosize="{ minRows: 5 }" :placeholder="placeholder" v-model="value"></el-input>
          <el-input ref="input" v-else :type="type" :placeholder="placeholder" v-model="value"></el-input>
        </div>
      </el-form-item>
      <div class="buttons">
        <button_plus :rounded="true" color="orange" @success="edit" v-if="edit_etat === true" :load_ok="load_ok" txt="Enregistrer"></button_plus>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "form_edit",
  data() {
    return {
        edit_etat: false,
        load_ok: false,
    }
  },
  props: [ 'label', 'type', 'placeholder', 'value', 'size', 'key_name', 'url', 'fw', 'not_empty' ],
  // watch: {
    // value() {
    //   this.  
    // }
  // },
  methods: {
    click_edit() {
      let self = this
      this.edit_etat = true
      setTimeout(() => {
        self.$refs.input.focus()
      }) 
    },
    edit() {
      let self = this
      if(this.not_empty === true && this.value.length <= 0) {
        this.$message({ message: 'Ce champ ne peut pas être vide', type: 'error' })
        this.load_ok = true
        setTimeout(() => self.load_ok = false)
        return
      }
      
      console.log('edit', this.key_name, this.url)
      this.$http.post(this.url, {
        key: this.key_name,
        value: this.value
      }).then(() => {
        console.log('Edit ok')
        this.edit_etat = false
        let self = this
        self.$store.dispatch("boutique_bo/sync");
      }).catch(e => console.log(e))
    }
  }
}
</script>


<style scoped>
  p { padding: 0; margin: 0; font-size: 16px }
  
  .el-input { margin-bottom: 10px; }
  
  
  .buttons { display: flex; justify-content: flex-end; margin-top: 0; }
  .buttons button { margin-bottom: 16px; }
  
  .title .int { font-weight: 400; }
  .title .int { width: 100%; }
  
    
</style>

<style>
.form_edit .buttons button { margin-top: 0px; margin-bottom: 16px; }
.form_edit .el-form-item { margin-bottom: 6px; }
@media only screen and (min-width: 800px) {
  .form_edit  { border-bottom: 1px solid #eee; margin-bottom: 20px; padding-bottom: 20px; }
  .form_edit .item { display: flex; align-items: center; margin-bottom: 0!important; }
  .form_edit .buttons { margin-top: 0; }
  .form_edit .buttons button { margin-top: 16px; }
  .form_edit .item .el-input { margin-bottom: 0; }
  .form_edit .item .title { margin-bottom: 0; }
  .form_edit .item .el-form-item__label { max-width: 200px; width: 200px; text-align: left; line-height: 20px; }
  .form_edit .el-form-item__content { width: calc(100% - 200px); }
}
</style>