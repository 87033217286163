<template>
  <div class="boutique adjust_width">
    <title_cat>Votre boutique</title_cat>


    <form_edit class="fo" :fw="true" size="big" label="Nom de votre restaurant" placeholder="Nom de votre restaurant" type="text" :value="$parent.boutique.boutique.name"
               key_name="name" url="/bo/boutique/edit" :not_empty="true"></form_edit>
    
    <form_edit size="big" :fw="true" label="Description de votre restaurant" placeholder="Description de votre restaurant" type="textarea" :value="$parent.boutique.boutique.description" 
               key_name="description" url="/bo/boutique/edit" :not_empty="true"></form_edit>
    
<!--    <el-upload-->
<!--        ref="upload"-->
<!--        :action="$api_ip + 'bo/boutique/image/cover'"-->
<!--        :before-upload="beforeAvatarUpload"-->
<!--        :multiple="false"-->
<!--        :on-error="error"-->
<!--        :on-success="handleAvatarSuccess"-->
<!--        :show-file-list="false"-->
<!--        class="upload_block"-->
<!--    >-->
<!--      <el-image v-if="imageUrl" :src="imageUrl" class="img" fit="cover"></el-image>-->
<!--      <el-image v-if="img" :src="$static_patch + img" class="img" fit="cover"></el-image>-->
<!--      <i v-else class="el-icon-plus upload_block-uploader-icon"><span>Largeur 1920 pixels minimum</span></i>-->
<!--    </el-upload>-->
    
    
    
    
  </div>
</template>

<script>

import form_edit from "@/components/bo/form_edit";

export default {
  name: "boutique",
  data() {
    return {
      'boutique': {},
      img: null, imageUrl: null
    }
  },
  components: { form_edit },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.img = res
      this.$http.post('/bo/boutique/edit', {
        id: this.$store.state.user.user.boutique,
        key: 'img',
        value: this.img
      }).then(() => {
        console.log('Edit ok')
      }).catch(e => console.log(e))
    }, 
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 200;

      if (!isJPG) this.$message.error('Avatar picture must be JPG format!');
      if (!isLt2M) this.$message.error('Avatar picture size can not exceed 2MB!');

      return isJPG && isLt2M;
    }
  },
  created() {
    // this.$http.get('/bo/boutique/' + this.$bo_boutique_id).then((response) => {
    //   this.boutique = response.data
    // })
  }
}
</script>

<style scoped>
  .fo { width: 100%; }
  
  
</style>